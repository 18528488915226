.containerPrincipal {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color:#EFEFEF;
    width: 100%;
    height: 100%;
    
    
    padding: 100px;
    
}
.containerSecundario{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center; 
    background-color:#EFEFEF;
   
    
}

/*/Este css es para la hoja de Password/*/

.principal{
  
   align-items: center;
   display: flex;
   height: 100vh;
}

.secundario{
    margin: 0 auto;
}


/*Animacion de la app*/

.App {
    text-align: center;
  }
  
  .App-logo {
    
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotateY(0deg);
    }
    to {
      transform: rotateY(360deg);
    }
  }
  
  .error{
    color:red;
    font-style: italic;
  }